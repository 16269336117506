






















import Vue from "vue";

export default Vue.extend({
  name: "CalendarController",
  methods: {
    handleClickMonth(diff: number) {
      console.log(diff)
      this.$emit("ChangeMonth", diff)
    },
  },
  props: {
    header: String
  }
})































import Vue from "vue";

declare interface DataTypes {
  colorMenu: boolean;
  color: any;
}

export default Vue.extend({
  name: "ColorInput",
  props: {
    label: String,
    errorMessages: Array,
    required: Boolean
  },
  data(): DataTypes {
    return {
      colorMenu: false,
      color: null
    }
  },
  computed: {
    colorDisplay(): string {
      return (this.color !== null) ? this.color.hex : ""
    },
  },
  methods: {
    send() {
      this.$emit("change", this.color)
    }
  }
})

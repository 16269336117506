
















































































import Vue from "vue"

import CalendarController from "@/components/CalendarComponents/CalendarController.vue";

import CalendarEvent, {EventAction} from "@/utils/calendarEvent";
import {EditMode} from "@/utils/calendar"
import {format} from "@/utils/date";
import {EventActions} from "@/utils/http";

declare interface CalendarData {
  eventActions: EventActions;
  calendarType: string;
  calendarStart: Date;
  events: Array<CalendarEvent>;
  eventsSet: Set<string>;
  selectedDetailOpen: boolean;
  selectedElement: EventTarget | null;
  selectedEvent: CalendarEvent | object | null;
  deleteSelectedEventDialog: boolean;
  dragEvent: CalendarEvent | null;
  dragEventUpdateFrag: boolean;
  value: string | null;
}

export default Vue.extend({
  name: "Calendar",
  components: {CalendarController},
  props: {
    eventAction: EventAction
  },
  data(): CalendarData {
    return {
      eventActions: new EventActions(this.$store.getters["profile/getToken"], this.$store.getters["profile/getExpire"]),
      calendarType: "month",
      calendarStart: new Date(),
      events: [],
      eventsSet: new Set(),
      selectedDetailOpen: false,
      selectedElement: null,
      selectedEvent: {},
      deleteSelectedEventDialog: false,
      dragEvent: null,
      dragEventUpdateFrag: false,
      value: null,
    }
  },
  mounted: function () {
    // this.addEvent(
    //     new CalendarEvent(`Test Event #${this.events.length}`, new Date(), new Date(), "This is test event.", "#F86565")
    // )
  },
  methods: {
    initCalendar({start, end}: { start: any; end: any }) {
      const startDate = new Date(Date.parse(start.date))
      const startString = format(startDate, "%Y-%m-%dT%H:%M")
      this.eventActions.getList(startString, this.calendarType,
          response => {
            response.data.forEach((data: any) => {
              if (!this.eventsSet.has(data.id)) {
                const event = new CalendarEvent(
                    data.title,
                    new Date(data.start),
                    new Date(data.end),
                    data.summary,
                    data.color,
                    data.location,
                    data.timed,
                    data.id
                )
                this.events.push(event)
                this.eventsSet.add(data.id)
              }
            })
          },
          response => {
            if (response.status === 401) {
              this.$store.commit("profile/setLoggedIn", false)
              this.$router.push("login")
            }

          }
      )
    },

    handleEventClick({event, nativeEvent}: { event: CalendarEvent; nativeEvent: MouseEvent }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedDetailOpen = true
        }, 10)
      }
      if (this.selectedDetailOpen) {
        this.selectedDetailOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },

    handleEventDragStart({event}: { event: CalendarEvent }) {
      this.dragEvent = event
    },
    handleEventDragMoveDay({date}: { date: string }) {
      if (this.dragEvent !== null) {
        const newDate = new Date(date)
        const event: CalendarEvent = this.dragEvent
        const start = event.start
        const prevStart = new Date(start.getTime())
        let end = event.end

        const duration = end.getTime() - start.getTime()
        const startTime = start.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate())
        end = new Date(startTime + duration)

        if (prevStart !== start) {
          this.dragEventUpdateFrag = true

          this.dragEvent.start = start
          this.dragEvent.end = end
        }
      }
    },
    handleEventDragEndDay() {
      if (this.dragEvent !== null && this.dragEventUpdateFrag) {
        this.partialUpdateEvent(this.dragEvent, {
          start: this.dragEvent.start,
          end: this.dragEvent.end,
        })
        this.dragEventUpdateFrag = false
      }
      this.dragEvent = null
    },
    handleEventDragCancel() {
      console.log()
    },

    handleSelectedEventDelete() {
      if (this.selectedEvent instanceof CalendarEvent) {
        this.deleteEvent(this.selectedEvent)

        this.deleteSelectedEventDialog = false
        this.selectedDetailOpen = false
      }
    },
    handleSelectedEventCancel() {
      this.deleteSelectedEventDialog = false
      this.selectedDetailOpen = false
    },

    handleDayClick({date}: { date: string }) {
      if (this.eventAction && this.eventAction.editMode === EditMode.MODE_ADD && this.eventAction.eventReady) {
        const eventData = this.eventAction.eventData
        this.createEvent(
            eventData.title,
            new Date(`${date}T${eventData.startTimeStr}`),
            new Date(`${date}T${eventData.endTimeStr}`),
            eventData.summary,
            eventData.color,
            eventData.location,
            eventData.timed
        )
      }
    },
    handleDateClick({date}: { date: string }) {
      console.log(this.events)
    },

    handleChangeMonth(diff: number) {
      const calendar: any = this.$refs.calendar
      calendar.move(diff)
    },
    showHeader(): string {
      const calendar: any = this.$refs.calendar
      return (calendar) ? calendar.title : ""
    },


    createEvent(title: string, start: Date, end: Date, summary: string, color: string, location: string, timed: boolean) {
      const newEvent = new CalendarEvent(
          title,
          start,
          end,
          summary,
          color,
          location,
          timed
      )
      this.eventActions.create(newEvent, (res, ev) => {
        this.events.push(ev)
      }, response => {
        console.log(response.status)
      })
    },
    updateEvent(event: CalendarEvent) {
      this.eventActions.update(event,
          (res, ev) => {
            console.log(res)
          })
    },
    partialUpdateEvent(event: CalendarEvent, fields: Record<string, any>) {
      this.eventActions.partialUpdate(event, fields,
          (res, ev) => {
            console.log(res)
          })
    },
    deleteEvent(event: CalendarEvent) {
      this.eventActions.destroy(event,
          (response, event) => {
            const events = this.events
            events.forEach((ev: CalendarEvent, idx) => {
              if (ev.id === event.id) {
                events.splice(idx, 1)
              }
            })
            this.events = events
            if (event.id !== null) {
              this.eventsSet.delete(event.id)
            }
          },
          response => {
            if (response.status === 401) this.$router.push("login")
          }
      )
    },

    formatTime(date: Date | {}): string {
      return (date instanceof Date) ? format(date, "%H:%M") : ""
    },

  },
})






























































import Vue from "vue";
import {required, maxLength} from "vuelidate/lib/validators"
import TimeInput from "@/components/CalendarComponents/TimeInput.vue";
import ColorInput from "@/components/CalendarComponents/ColorInput.vue";
import {EditMode} from "@/utils/calendar";
import {EventAction} from "@/utils/calendarEvent";

const maxTitle = 64
const maxSummary = 200
const maxLocation = 128

declare interface DataTypes {
  title: string;
  maxTitle: number;
  startTimeStr: string;
  endTimeStr: string;
  summary: string;
  maxSummary: number;
  color: string;
  location: string;
  maxLocation: number;
  timed: boolean;
  editMode: number;
}

export default Vue.extend({
  name: "CalendarForm",
  components: {
    TimeInput,
    ColorInput
  },
  data(): DataTypes {
    return {
      title: "",
      maxTitle: maxTitle,
      startTimeStr: "",
      endTimeStr: "",
      summary: "",
      maxSummary: maxSummary,
      color: "#FFFFFF",
      location: "",
      maxLocation: maxLocation,
      timed: false,
      editMode: EditMode.MODE_DETAIL,
    }
  },
  validations: {
    title: {required, maxLength: maxLength(maxTitle)},
    summary: {maxLength: maxLength(maxSummary)},
    location: {maxLength: maxLength(maxLocation)}
  },
  computed: {
    // Errors
    titleErrors(): Array<string> {
      const errors: Array<string> = []
      if (!this.$v.title.$dirty) return errors
      !this.$v.title.required && errors.push("Title is required")
      !this.$v.title.maxLength && errors.push(`Title must be ~${maxTitle} chars`)
      return errors
    },
    timeErrors(): Array<string> {
      const errors: Array<string> = []
      return errors
    },
    summaryErrors(): Array<string> {
      const errors: Array<string> = []
      if (!this.$v.summary.$dirty) return errors
      !this.$v.summary.maxLength && errors.push(`Summary must be ~${maxSummary} chars`)
      return errors
    },
    locationErrors(): Array<string> {
      const errors: Array<string> = []
      if (!this.$v.location.$dirty) return errors
      !this.$v.location.maxLength && errors.push(`Location must be ~${maxLocation} chars`)
      return errors
    },
  },
  methods: {
    handleStartTimeChanged(timeStr: string) {
      this.startTimeStr = timeStr
      this.emitToParent()
    },
    handleEndTimeChanged(timeStr: string) {
      this.endTimeStr = timeStr
      this.emitToParent()
    },
    handleColorChanged({hex}: {hex: string}) {
      this.color = hex
      this.emitToParent()
    },
    handleInputChanged() {
      this.emitToParent()
    },
    handleEditModeChanged(editMode: number) {
      this.editMode = editMode
      this.emitToParent()
    },
    emitToParent() {
      this.$emit("change", new EventAction(
          this.editMode,
          this.isValidData(),
          {
            title: this.title,
            startTimeStr: this.startTimeStr,
            endTimeStr: (this.endTimeStr === "") ? this.startTimeStr: this.endTimeStr,
            summary: this.summary,
            color: this.color,
            location: this.location,
            timed: !this.timed
          }
      ))
    },
    isValidData() {
      return (!this.$v.title.$invalid
          && !this.$v.summary.$invalid
          && this.startTimeStr !== ""
      )
    }
  }
})



































import Vue from "vue";

declare interface DataTypes {
  timeMenu: boolean;
  timeValue: string | null;
}

export default Vue.extend({
  name: "TimeInput",
  props: {
    label: String,
    errorMessages: Array,
    required: Boolean
  },
  data(): DataTypes {
    return {
      timeMenu: false,
      timeValue: null
    }
  },
  computed: {
    timeDisplay(): string {
      return (this.timeValue !== null) ? this.timeValue : ""
    },
  },
  methods: {
    send() {
      this.$emit("change", this.timeValue)
    }
  }
})

export default class CalendarEvent {
    private _id: string | null
    private _name: string
    private _start: Date
    private _end: Date
    private _summary: string
    private _color: string
    private _location: string
    private _timed: boolean // 終日=true
    // private _outside: boolean
    // private _singleline: boolean
    // private _overlapsNoon: boolean

    constructor(name: string, start: Date, end: Date, summary: string, color: string, location: string, timed = true, id?: string) {
        this._id = id !== undefined ? id : null
        this._name = name
        this._start = start
        this._end = end
        this._summary = summary
        this._color = color
        this._location = location
        this._timed = timed
    }

    get id(): string | null {return this._id}
    set id(uuid) {this._id = uuid}

    get name(): string {return this._name}
    set name(n: string) {this._name = n}

    get start(): Date {return this._start}
    set start(date: Date) {this._start = date}

    get end(): Date {return this._end}
    set end(date: Date) {this._end = date}

    get summary(): string {return this._summary}
    set summary(text: string) {this._summary = text}

    get color(): string {return this._color}
    set color(color: string) {this._color = color}

    get location(): string {return  this._location}
    set location(location: string) {this._location = location}

    get timed(): boolean {return this._timed}
    set timed(timed: boolean){this._timed = timed}
}

export class EventAction {
    editMode: number
    eventReady: boolean
    eventData: {
        title: string;
        startTimeStr: string;
        endTimeStr: string;
        summary: string;
        color: string;
        location: string;
        timed: boolean;
    }

    constructor(editMode: number, eventReady: boolean, eventData: {
        title: string;
        startTimeStr: string;
        endTimeStr: string;
        summary: string;
        color: string;
        location: string;
        timed: boolean;
    }) {
        this.editMode = editMode
        this.eventData = eventData
        this.eventReady = eventReady
    }
}













import Vue from "vue";
import Calendar from "@/components/CalendarComponents/Calendar.vue";
import CalendarForm from "@/components/CalendarComponents/CalendarForm.vue";
import {EventAction} from "@/utils/calendarEvent";

declare interface DataTypes {
  eventAction: EventAction | null;
}

export default Vue.extend({
  components: {
    Calendar,
    CalendarForm
  },
  data(): DataTypes {
    return {
      eventAction: null
    }
  },
  methods: {
    createEvent(event: Event) {
      const calendar: any = this.$refs.calendar
      calendar.addEvent(event)
    },
    handleCalendarFormChanged(eventAction: EventAction) {
      this.eventAction = eventAction
    }
  }
})

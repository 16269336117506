




import Vue from "vue";
import CalendarApp from "@/components/CalendarComponents/CalendarApp.vue";

export default Vue.extend({
  name: "Calendar",
  components: {
    CalendarApp
  }
})
